<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<header :style="cssVarColor">
			<h1>{{ $t('time_line') }}</h1>
		</header>
		
		<div v-if="!isLoading && timelineError" class="error-code">
			{{ $t('no_resources') }}
		</div>
		
		<main v-if="!isLoading && timeline">
			<div v-if="steps[index].locale" class="step-info">
				<p class="info">{{ steps[index].locale.instructions }}</p>
				<div class="location-link" @click="showLocationImg = true">
					<i class="mm-icon mm-icon-location red big"/>
					<p>{{ $t('show_route') }}</p>
				</div>
			</div>
			
			<div v-if="activeHall" class="artworks-grid">
				<div v-for="artwork in activeHall.artworkImages"
				     :key="artwork.idArtwork"
				     class="artwork"
				     @click="goToArtwork(artwork.idArtwork)">
					<img :src="artwork.mainImageUrl" alt="" class="artwork-image">
					<div v-if="artwork.mainAudioUrl" class="sound-icon">
						<i class="mm-icon mm-icon-audio white"/>
					</div>
				</div>
			</div>
			
			<aside class="carousel">
				<div v-for="step in steps"
				     :key="step.idStep"
				     class="step"
				     :style="cssVarColor"
				     :class="{ 'step-active': step.index === index }"
				     @click="index = step.index">
					
					<div v-if="step.index === '0'">
						{{ $t('start') }}
					</div>
					
					<div v-else-if="step.idHall">
						<p class="hall-name">{{ getHall(step.idHall).upperName }}</p>
						<p class="hall-number">{{ getHall(step.idHall).bottomName }}</p>
					</div>
					
					<div v-else class="icon-container">
						<i class="mm-icon mm-icon-info" :style="cssVarColor" :class="{ 'white': step.index === index }"/>
					</div>
				</div>
			</aside>
		</main>
		
		<div v-if="!isLoading && timeline" v-show="showLocationImg" class="step-map">
			<h2>{{ $t('show_route') }}</h2>
			
			<pinch-zoom ref="locationImg"
			            class="step-map-pinch"
			            doubleTap="true"
			            wheel="true"
			            limitZoom="5"
			            disableZoomControl="never"
			            backgroundColor="rgba(0,0,0,0)"
			            autoHeigh="true">
				<div class="step-map-location">
					<img class="step-map-location-image" :src="steps[index].locationImageUrl" alt="">
				</div>
			</pinch-zoom>
			
			<div class="step-map-image-footer" @click="showLocationImg = false">
				<i class="mm-icon mm-icon-close big"/>
			</div>
		</div>
		
		<footer :style="footerCSSVars">
			<div class="footer-buttons">
				<button class="button-white" @click="goBack()">
					<i class="mm-icon mm-icon-arrow-back big"/>
				</button>
				<button v-if="museum.recognitionAvailable" class="button-recognition" @click="goToRecognition()">
					<img v-if="customization && customization.recognitionIconUrl" class="recognition-icon" :src="customization.recognitionIconUrl" alt="">
					<img v-else class="recognition-icon" src="@/assets/icons/pointplay.png" alt="">
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import PinchZoom from 'vue-pinch-zoom';
import {setFullScreen} from "@/store/helpers";
import firebase from "firebase/app";

export default {
	name: "Timeline",
	
	components: {
		Loading,
		PinchZoom
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			idTimeline: this.$route.params.idTimeline || 0,
			index: "0",
			showLocationImg: false,
			navigate: true,
			sectionTimeline: null,
			keyboardCustomization: null,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			timeline: state => state.timeline.timeline,
			timelineError: state => state.timeline.timelineError,
			navigationSections: state => state.navigationSection.navigationSections,
			navigationSectionsError: state => state.navigationSection.navigationSectionsError,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('time_line');
			if(this.timeline?.locale?.title) {
				locationName = this.timeline.locale.title;
			}
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		steps() {
			return this.timeline.steps;
		},
		
		halls() {
			return this.timeline.halls;
		},
		
		activeHall() {
			return this.timeline.halls.find(hall => hall.idHall === this.steps[this.index].idHall);
		},
		
		cssVarColor() {
			let color = null;
			if(!this.isLoading) {
				color = {
					'--bg-color': (this.sectionTimeline?.active && this.sectionTimeline.lineColor) ? this.sectionTimeline.lineColor : '#94af49'
				};
			}
			return color;
		},
		
		footerCSSVars() {
			return {
				'--recognition-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.recognitionColor : '#f06140',
			};
		},
		
		cssVars() {
			return {
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0',
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : '#ffffff',
			};
		}
	},
	
	created() {
		debug.log("[timeline] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'Timeline', params: { idMuseum: this.encrypted, idTimeline: this.idTimeline } });
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(async() => {
					await this.getTimeline();
					this.getNavigationSections();
					this.sectionTimeline = this.navigationSections.find((s) => s.sectionType === 'TIMELINE');
					if(this.museum.keyboardCustomization && this.museum.keyboardCustomization.active) {
						this.keyboardCustomization = this.museum.keyboardCustomization;
					}
					await this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
					
					if(this.navigate && this.$route.query.artwork) {
						this.navigate = false;
						let step = this.steps.find((step) => {
							let hall = null;
							if(step.idHall) hall = this.halls.find((hall) => hall.idHall === step.idHall);
							let artwork = null;
							if(hall?.artworkIds?.length) artwork = hall.artworkIds.find((artwork) => artwork.idArtwork === parseInt(this.$route.query.artwork));
							return artwork !== null;
						});
						
						if(step) {
							this.index = step.index;
						}
					}
					
					if(firebase.app().options.measurementId) {
						debug.log("Logging page_view analytics");
						firebase.analytics().logEvent('page_view', {
							page_location: this.$router.currentRoute.fullPath,
							page_title: this.pageTitle
						});
					} else {
						debug.log("No measurementId found, no analytics sent");
					}
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('timeline', ['getTimeline']),
		...mapActions('navigationSection', ['getNavigationSections']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		goToRecognition() {
			setFullScreen();
			this.$router.push({name: 'Recognition', params: { idMuseum: this.encrypted} });
		},
		
		goBack() {
			setFullScreen();
			this.$router.push({ name: 'TimelineList', params: { idMuseum: this.encrypted } });
		},
		
		goToArtwork(idArtwork) {
			setFullScreen();
			this.$router.push({ name: 'Artwork', params: { idMuseum: this.encrypted, idArtwork: idArtwork } });
		},
		
		getHall(idHall) {
			return this.halls.find(hall => hall.idHall === idHall);
		}
	},
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	height: 100%;
	
	.error-code {
		padding: 80px 20px 100px 20px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}
	
	header {
		position: fixed;
		top: 0;
		height: 70px;
		width: 100%;
		background-color: var(--bg-color);
		padding: 20px;
		z-index: 1;
		
		h1 {
			color: white;
			font-size: 24px;
			font-weight: normal;
		}
	}
	
	main {
		width: 100%;
		height: 100%;
		padding: 90px 0 180px;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		background-color: white;
		
		.step-info {
			flex: 1;
			height: 100%;
			padding: 80px 20px 20px;
			display: flex;
			flex-direction: column;
			
			.info {
				flex-grow: 1;
				max-height: 300px;
				overflow-y: scroll;
				font-size: 28px;
				margin-bottom: 20px;
			}
			
			.location-link {
				flex-shrink: 2;
				color: $pink;
				display: flex;
				align-items: center;
				
				i {
					margin-right: 10px;
				}
				
				p {
					font-size: 22px;
				}
			}
		}
		
		.artworks-grid {
			line-height: 0;
			column-count: 2;
			column-gap: 30px;
			padding: 0 10px;
			
			.artwork {
				position: relative;
				margin-bottom: 20px;
				box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
				
				.artwork-image {
					width: 100%;
					height: auto;
				}
				
				.sound-icon {
					position: absolute;
					bottom: 5px;
					right: 5px;
					background-color: var(--main-buttons-color);
					width: 30px;
					height: 30px;
					border-radius: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					
					.mm-icon {
						margin-bottom: -5px;
						color: var(--main-buttons-text-color);
					}
				}
			}
		}
		
		.carousel {
			display: flex;
			overflow-x: scroll;
			min-height: 70px;
			position: fixed;
			bottom: 90px;
			left: 0;
			right: 0;
			
			.step {
				box-shadow: 0 10px 3px rgba(0,0,0,0.16), 0 -1px 6px rgba(0,0,0,0.23);
				background-color: white;
				min-width: 60px;
				padding: 5px;
				display: flex;
				align-items: center;
				color: var(--bg-color);
				
				&.step-active {
					background-color: var(--bg-color);
					color: white;
					
					.icon-container {
						.mm-icon {
							color: white;
							font-size: 30px;
						}
					}
				}
				
				&:not(:last-child) {
					margin-right: 5px;
				}
				
				.icon-container {
					height: 65px;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					
					.mm-icon {
						font-size: 28px;
					}
					
					.mm-icon-info {
						color: var(--bg-color);
					}
				}
				
				.hall-name {
					font-size: 16px;
				}
				
				.hall-number {
					font-size: 24px;
				}
			}
		}
	}
	
	.step-map {
		position: absolute;
		z-index: 10;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: white;
		display: flex;
		flex-direction: column;
		
		h2 {
			width: 100%;
			font-weight: normal;
			font-size: 22px;
			padding: 20px 20px;
			background-color: white;
			border-bottom: 1px solid $gray-lightest;
		}
		
		.step-map-pinch {
			width: 100%;
			flex: 2;
			display: flex;
			
			.step-map-location {
				height: calc(100vh - 90px);
				display: flex;
				justify-content: center;
				align-items: center;
				
				.step-map-location-image {
					width: 100%;
					object-fit: cover;
				}
			}
		}
		
		.step-map-image-footer {
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: white;
			box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.footer-buttons {
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: center;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				
				&.button-white {
					background-color: white;
					color: black;
				}
				
				&.button-recognition {
					color: white;
					background-color: var(--recognition-color);
					
					.recognition-icon {
						max-width: 60%;
						max-height: 80%;
					}
				}
			}
		}
	}
}
</style>
